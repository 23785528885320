<template>
  <div>
    <ToolbarAuth />
    <report />
    <error-report />
    <div class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="roodt d-flex justify-center">
          <v-card class="ma-10 transparent" outlined width="100%">
            <div v-if="this.step === 1">
              <div v-if="formal">
                <h3>
                  {{ textIntro[1].header }}
                </h3>
                <br />
                {{ textIntro[1].texta }}
                <br /><br />
                {{ textIntro[1].textb }}
                <br /><br />
                {{ textIntro[1].textc }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[1].headerInf }}
                </h3>
                <br />
                {{ textIntro[1].textaInf }}
                <br /><br />
                {{ textIntro[1].textbInf }}
                <br /><br />
                {{ textIntro[1].textcInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>

            <div v-if="this.step === 2">
              <div v-if="formal">
                <h3>
                  {{ textIntro[2].header }}
                </h3>
                <br />
                {{ textIntro[2].texta }}
                <br /><br />
                {{ textIntro[2].textb }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[2].headerInf }}
                </h3>
                <br />
                {{ textIntro[2].textaInf }}
                <br /><br />
                {{ textIntro[2].textbInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 3">
              <div v-if="formal">
                <h3>
                  {{ textIntro[3].header }}
                </h3>
                <br />
                {{ textIntro[3].texta }}
                <br /><br />
                {{ textIntro[3].textb }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[3].headerInf }}
                </h3>
                <br />
                {{ textIntro[3].textaInf }}
                <br /><br />
                {{ textIntro[3].textbInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 4">
              <div v-if="formal">
                <h3>
                  {{ textIntro[4].header }}
                </h3>
                <br />
                {{ textIntro[4].texta }}
                <br /><br />
                {{ textIntro[4].textb }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[4].headerInf }}
                </h3>
                <br />
                {{ textIntro[4].textaInf }}
                <br /><br />
                {{ textIntro[4].textbInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 5">
              <div v-if="formal">
                <h3>
                  {{ textIntro[5].header }}
                </h3>
                <br />
                {{ textIntro[5].texta }}
                <br /><br />
                {{ textIntro[5].textb }}
                <br /><br />
                {{ textIntro[5].textc }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[5].headerInf }}
                </h3>
                <br />
                {{ textIntro[5].textaInf }}
                <br /><br />
                {{ textIntro[5].textbInf }}
                <br /><br />
                {{ textIntro[5].textcInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 6">
              <div v-if="formal">
                <h3>
                  {{ textIntro[6].header }}
                </h3>
                <br />
                {{ textIntro[6].texta }}
                <br /><br />
                {{ textIntro[6].textb }}
                <br /><br />
                {{ textIntro[6].textc }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[6].headerInf }}
                </h3>
                <br />
                {{ textIntro[6].textaInf }}
                <br /><br />
                {{ textIntro[6].textbInf }}
                <br /><br />
                {{ textIntro[6].textcInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 7">
              <div v-if="formal">
                <h3>
                  {{ textIntro[7].header }}
                </h3>
                <br />
                {{ textIntro[7].texta }}
                <br /><br />
                {{ textIntro[7].textb }}
                <br /><br />
                {{ textIntro[7].textc }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[7].headerInf }}
                </h3>
                <br />
                {{ textIntro[7].textaInf }}
                <br /><br />
                {{ textIntro[7].textbInf }}
                <br /><br />
                {{ textIntro[7].textcInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
            <div v-if="this.step === 8">
              <div v-if="formal">
                <h3>
                  {{ textIntro[8].header }}
                </h3>
                <br />
                {{ textIntro[8].texta }}
                <br /><br />
                {{ textIntro[8].textb }}
                <br /><br />
                {{ textIntro[8].textc }}
                <br /><br />
                {{ textIntro[8].textd }}
                <br /><br />
                {{ textIntro[8].texte }}
                <br /><br />
                {{ firstNameUser }} {{ lastNameUser }} <br /><br />
                {{ textIntro[8].textf }}
                {{ textIntro[8].textg }}
                <br /><br />
              </div>
              <div v-if="!formal">
                <h3>
                  {{ textIntro[8].headerInf }}
                </h3>
                <br />
                {{ textIntro[8].textaInf }}
                <br /><br />
                {{ textIntro[8].textbInf }}
                <br /><br />
                {{ textIntro[8].textcInf }}
                <br /><br />
                {{ textIntro[8].textdInf }}
                <br /><br />
                {{ textIntro[8].texteInf }}
                <br /><br />
                {{ firstNameUser }} {{ lastNameUser }} <br /><br />
                {{ textIntro[8].textfInf }}
                {{ textIntro[8].textgInf }}
                <br /><br />
              </div>
              <div class="d-none d-md-flex d-lg-flex">
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
                <br /><br />
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-card class="ma-10 transparent" outlined width="100%">
            <step-text
              :stepText="textIntro[1].header"
              :number="1"
              class="mb-5"
              :kleur="colorStep1"
            />

            <div id="step1" v-if="this.step === 1">
              <CustomDivider />
              <div v-if="formal">
                <StatementText :statement="textIntro[1].textc" />
              </div>
              <div v-if="!formal">
                <StatementText :statement="textIntro[1].textcInf" />
              </div>
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <statement-text :statement="textIntro[1].slider_a" />
                  <ta-slider3 v-model="sl_a" />
                  <statement-text :statement="textIntro[1].slider_b" />
                  <ta-slider3 v-model="sl_b" />
                  <statement-text :statement="textIntro[1].slider_c" />
                  <ta-slider3 v-model="sl_c" />
                  <statement-text :statement="textIntro[1].slider_d" />
                  <ta-slider3 v-model="sl_d" />
                  <statement-text :statement="textIntro[1].slider_e" />
                  <ta-slider3 v-model="sl_e" />
                  <statement-text :statement="textIntro[1].slider_f" />
                  <ta-slider3 v-model="sl_f" />

                  <div v-if="formal">
                    <StatementText :statement="textIntro[1].question_a" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[1].question_aInf" />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-radio-group
                      v-model="question_a"
                      :succes="valid"
                      :error-messages="errors"
                    >
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label> Ja </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label> Nee </template>
                      </v-radio>
                    </v-radio-group>
                    <div v-if="question_a === 'ke1'">
                      <div v-if="formal">
                        <StatementText :statement="textIntro[1].question_b" />
                      </div>
                      <div v-if="!formal">
                        <StatementText
                          :statement="textIntro[1].question_bInf"
                        />
                      </div>
                      <base-val-area
                        label="Functionaliteiten"
                        rules="max:250"
                        v-model="text_a"
                      />
                    </div>
                    <v-row class="mt-10">
                      <v-spacer />
                      <v-btn
                        color="primary"
                        small
                        class="mb-5 mr-8"
                        @click="handleSubmit(endStep1)"
                        :disabled="invalid || !validate"
                        >Verder</v-btn
                      >
                    </v-row>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <CustomDivider />
            </div>
            <step-text
              :stepText="textIntro[2].header"
              :number="2"
              class="mb-5 mt-5"
              :kleur="colorStep2"
            />
            <div id="step2" v-if="this.step === 2">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <CustomDivider />
                <div v-if="formal">
                  <StatementText :statement="textIntro[2].textb" />
                </div>
                <div v-if="!formal">
                  <StatementText :statement="textIntro[2].textbInf" />
                </div>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <v-radio-group v-model="question_b" :succes="valid">
                    <v-radio value="ke1" color="primary">
                      <template v-slot:label>
                        Ja, dit is goed ingericht er hoeven geen extra stappen
                        te worden genomen
                      </template>
                    </v-radio>
                    <v-radio value="ke2" color="primary">
                      <template v-slot:label>
                        Dit kan qua efficiency en autorisaties beter worden
                        ingericht
                      </template>
                    </v-radio>
                    <v-radio value="ke3" color="primary">
                      <template v-slot:label>
                        Ik ben niet goed op de hoogte wat er mogelijk is m.b.t
                        de bankkoppeling
                      </template>
                    </v-radio>
                  </v-radio-group>
                </ValidationProvider>
                <CustomDivider />
                <v-row class="mt-10">
                  <v-spacer />
                  <btnback :on-click="backStep2" />
                  <v-btn
                    color="primary"
                    small
                    class="mb-5 mr-8"
                    @click="handleSubmit(endStep2)"
                    :disabled="invalid || !validate"
                    >Verder</v-btn
                  >
                </v-row>
              </ValidationObserver>
            </div>
            <step-text
              :stepText="textIntro[3].header"
              :number="3"
              class="mb-5"
              :kleur="colorStep3"
            />
            <div id="step3" v-if="this.step === 3">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <CustomDivider />
                  <div v-if="formal">
                    <StatementText :statement="textIntro[3].textb" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[3].textbInf" />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-radio-group v-model="question_c" :succes="valid">
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label> Ja </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label> Nee </template>
                      </v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                  <div v-if="question_c === 'ke1'">
                    <div v-if="formal">
                      <statement-text :statement="textIntro[3].slider_a" />
                    </div>
                    <div v-if="!formal">
                      <statement-text :statement="textIntro[3].slider_aInf" />
                    </div>
                    <ta-slider2 v-model="sl_g" />
                  </div>
                  <div v-if="question_c === 'ke2'">
                    <div v-if="formal">
                      <statement-text :statement="textIntro[3].question_a" />
                    </div>
                    <div v-if="!formal">
                      <statement-text :statement="textIntro[3].question_aInf" />
                    </div>

                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-radio-group v-model="question_d" :succes="valid">
                        <v-radio value="ke1" color="primary">
                          <template v-slot:label> Ja </template>
                        </v-radio>
                        <v-radio value="ke2" color="primary">
                          <template v-slot:label> Nee </template>
                        </v-radio>
                        <v-radio value="ke3" color="primary">
                          <template v-slot:label>
                            Weet niet / onbekend</template
                          >
                        </v-radio>
                      </v-radio-group>
                    </ValidationProvider>
                  </div>
                  <CustomDivider />
                  <v-row class="mt-10">
                    <v-spacer />
                    <btnback :on-click="backStep3" />
                    <v-btn
                      color="primary"
                      small
                      class="mb-5 mr-8"
                      @click="handleSubmit(endStep3)"
                      :disabled="invalid || !validate"
                      >Verder</v-btn
                    >
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>

            <step-text
              :stepText="textIntro[4].header"
              :number="4"
              class="mb-5"
              :kleur="colorStep4"
            />
            <div id="step4" v-if="this.step === 4">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <CustomDivider />
                  <div v-if="formal">
                    <StatementText :statement="textIntro[4].textb" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[4].textbInf" />
                  </div>
                  <statement-text :statement="textIntro[4].slider_a" />
                  <ta-slider2 v-model="sl_h" />
                  <statement-text :statement="textIntro[4].slider_b" />
                  <ta-slider2 v-model="sl_i" />
                  <statement-text :statement="textIntro[4].slider_c" />
                  <ta-slider2 v-model="sl_j" />
                  <statement-text :statement="textIntro[4].slider_d" />
                  <ta-slider2 v-model="sl_k" />
                  <statement-text :statement="textIntro[4].slider_e" />
                  <ta-slider2 v-model="sl_l" />
                  <statement-text :statement="textIntro[4].slider_f" />
                  <ta-slider2 v-model="sl_m" />
                  <CustomDivider />
                  <v-row class="mt-10">
                    <v-spacer />
                    <btnback :on-click="backStep4" />
                    <v-btn
                      color="primary"
                      small
                      class="mb-5 mr-8"
                      @click="handleSubmit(endStep4)"
                      :disabled="invalid || !validate"
                      >Verder</v-btn
                    >
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
            <step-text
              :stepText="textIntro[5].header"
              :number="5"
              class="mb-5"
              :kleur="colorStep5"
            />
            <div id="step5" v-if="this.step === 5">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <CustomDivider />
                  <div v-if="formal">
                    <StatementText :statement="textIntro[5].textb" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[5].textbInf" />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <StatementText :statement="textIntro[5].textcInf" />
                    <v-radio-group v-model="question_e" :succes="valid">
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label>
                          Direct na elke mutatie
                        </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label>
                          Dagelijks dienen alle resultaten te zijn bijgewerkt
                        </template>
                      </v-radio>
                      <v-radio value="ke3" color="primary">
                        <template v-slot:label>
                          Zo snel mogelijk aan het einde van de
                          kalendermaand</template
                        >
                      </v-radio>
                      <v-radio value="ke4" color="primary">
                        <template v-slot:label>
                          Zo snel mogelijk aan het einde van het kwartaal
                        </template>
                      </v-radio>
                      <v-radio value="ke5" color="primary">
                        <template v-slot:label> Anders, namelijk </template>
                      </v-radio>
                    </v-radio-group>
                    <div v-if="question_e === 'ke5'">
                      <base-val-area
                        label="Anders"
                        rules="max:250"
                        v-model="text_b"
                      />
                    </div>
                  </ValidationProvider>
                  <CustomDivider />
                  <v-row class="mt-10">
                    <v-spacer />
                    <btnback :on-click="backStep5" />
                    <v-btn
                      color="primary"
                      small
                      class="mb-5 mr-8"
                      @click="handleSubmit(endStep5)"
                      :disabled="invalid || !validate"
                      >Verder</v-btn
                    >
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
            <step-text
              :stepText="textIntro[6].header"
              :number="6"
              class="mb-5"
              :kleur="colorStep6"
            />
            <div id="step6" v-if="this.step === 6">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <CustomDivider />
                  <div v-if="formal">
                    <StatementText :statement="textIntro[6].textc" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[6].textcInf" />
                  </div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-radio-group v-model="question_f" :succes="valid">
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label> Ja </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label> Nee </template>
                      </v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                  <CustomDivider />
                  <v-row class="mt-10">
                    <v-spacer />
                    <btnback :on-click="backStep6" />
                    <v-btn
                      color="primary"
                      small
                      class="mb-5 mr-8"
                      @click="handleSubmit(endStep6)"
                      :disabled="invalid || !validate"
                      >Verder</v-btn
                    >
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
            <step-text
              :stepText="textIntro[7].header"
              :number="7"
              class="mb-5"
              :kleur="colorStep7"
            />
            <div id="step7" v-if="this.step === 7">
              <ValidationObserver
                ref="obs"
                v-slot="{ invalid, validate, handleSubmit }"
              >
                <v-form ref="form">
                  <CustomDivider />
                  <div v-if="formal">
                    <StatementText :statement="textIntro[7].textc" />
                  </div>
                  <div v-if="!formal">
                    <StatementText :statement="textIntro[7].textcInf" />
                  </div>
                  <v-checkbox
                    label="Inrichten koppelingen met andere systemen"
                    v-model="cb_a"
                    color="primary"
                  />
                  <v-checkbox
                    label="Stroomlijnen financiële processen"
                    v-model="cb_b"
                    color="primary"
                  />
                  <v-checkbox
                    label="Inrichten administratieve organisatie en bevoegdheden"
                    v-model="cb_c"
                    color="primary"
                  />
                  <v-checkbox
                    label="Aanreiken (financiële) kennis over de mogelijkheden van systemen "
                    v-model="cb_d"
                    color="primary"
                  />
                  <v-checkbox
                    label="Inzicht in liquiditeiten"
                    v-model="cb_e"
                    color="primary"
                  />
                  <v-checkbox
                    label="Overig te weten"
                    v-model="cb_f"
                    color="primary"
                  />
                  <div v-if="cb_f">
                    <base-val-area
                      label="Anders"
                      rules="max:250"
                      v-model="text_c"
                    />
                  </div>
                  <v-checkbox
                    label="Geen verdere ondersteuning nodig"
                    v-model="cb_g"
                  />
                  <CustomDivider />
                  <v-row class="mt-10">
                    <v-spacer />
                    <btnback :on-click="backStep7" />
                    <v-btn
                      color="primary"
                      small
                      class="mb-5 mr-8"
                      @click="handleSubmit(endStep7)"
                      :disabled="invalid || !validate"
                      >Verder</v-btn
                    >
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>

            <step-text
              :stepText="textIntro[8].header"
              :number="8"
              class="mb-5"
              :kleur="colorStep8"
            />
            <div id="step8" v-if="this.step === 8">
              <CustomDivider />
              <v-row class="mt-10 mb-10 justify-center">
                <v-btn
                  color="primary"
                  x-large
                  :disabled="disableBtnReport"
                  @click="getReport"
                  >rapportage</v-btn
                >
              </v-row>
              <CustomDivider />
              <v-row class="mt-10 mb-10">
                <v-spacer></v-spacer>
                <btnback :on-click="backStep8" />
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ToolbarAuth from "@/components/core/Toolbar";
import textData from "@/text/textApp.json";
import TaSlider2 from "@/components/core/ta-slider2";
import TaSlider3 from "@/components/core/ta-slider3";
// import ResultService from "@/services/ResultService";
import StepText from "@/components/stepText";
import StatementText from "@/components/StatementText";
import Btnback from "@/components/BtnBack";
import CustomDivider from "@/components/CustomDivider";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate";
import ResultService from "@/services/ResultService";
import Report from "@/components/core/report";
import AuthService from "@/services/AuthService";
import errorReport from "@/components/core/errorReport";
import BaseValArea from "@/components/input/BaseValArea";
export default {
  name: "Home",
  components: {
    BaseValArea,
    Report,
    ToolbarAuth,
    CustomDivider,
    Btnback,
    StatementText,
    StepText,
    // eslint-disable-next-line vue/no-unused-components
    TaSlider2,
    TaSlider3,
    ValidationObserver,
    ValidationProvider,
    errorReport,
  },
  data() {
    return {
      valid: false,
      question_a: "",
      question_b: "",
      question_c: "",
      question_d: "",
      question_e: "",
      question_f: "",
      firstNameUser: null,
      lastNameUser: null,
      formal: true,
      sl_a: 5,
      sl_b: 5,
      sl_c: 5,
      sl_d: 5,
      sl_e: 5,
      sl_f: 5,
      sl_g: 0,
      sl_h: 0,
      sl_i: 0,
      sl_j: 0,
      sl_k: 0,
      sl_l: 0,
      sl_m: 0,
      sl_n: 0,
      cb_a: false,
      cb_b: false,
      cb_c: false,
      cb_d: false,
      cb_e: false,
      cb_f: false,
      cb_g: false,
      text_a: null,
      text_b: null,
      text_c: null,
      textIntro: textData,
      disableBtn: false,
      valid2: false,
      valid3: false,
      valid4: false,
      colorStep0: "secondary",
      colorStep1: "primary",
      colorStep2: "secondary",
      colorStep3: "secondary",
      colorStep4: "secondary",
      colorStep5: "secondary",
      colorStep6: "secondary",
      colorStep7: "secondary",
      colorStep8: "secondary",
      step: 1,
      saving: false,
      loading: false,
      loading1: false,
      error: null,
      kel1: "ja",
      kel2: "nee",
      kel3: "wellicht",
      disableBtnReport: false,
    };
  },
  methods: {
    async endStep1() {
      await this.$vuetify.goTo(0);
      this.step++;
      this.$refs.observer.reset();
    },
    async endStep2() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep3() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep4() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep5() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep6() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep7() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async endStep8() {
      await this.$vuetify.goTo(0);
      this.step++;
    },
    async backStep2() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep3() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep4() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep5() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep6() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep7() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    async backStep8() {
      await this.$vuetify.goTo(0);
      this.step--;
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async getReport() {
      // eslint-disable-next-line
      console.log("gaat goed");
      const payload = {
        clientId: JSON.parse(localStorage.getItem("clientId")),
        sl_a: this.sl_a,
        sl_b: this.sl_b,
        sl_c: this.sl_c,
        sl_d: this.sl_d,
        sl_e: this.sl_e,
        sl_f: this.sl_f,
        sl_g: this.sl_g,
        sl_h: this.sl_h,
        sl_i: this.sl_i,
        sl_j: this.sl_j,
        sl_k: this.sl_k,
        sl_l: this.sl_l,
        sl_m: this.sl_m,
        sl_n: this.sl_n,
        cb_a: this.cb_a,
        cb_b: this.cb_b,
        cb_c: this.cb_c,
        cb_d: this.cb_d,
        cb_e: this.cb_e,
        cb_f: this.cb_f,
        cb_g: this.cb_g,
        question_a: this.question_a,
        question_b: this.question_b,
        question_c: this.question_c,
        question_d: this.question_d,
        question_e: this.question_e,
        question_f: this.question_f,
        text_a: this.text_a,
        text_b: this.text_b,
        text_c: this.text_c,
      };
      const payloadLog = {
        clientId: JSON.parse(localStorage.getItem("clientId")),
      };
      try {
        this.disableBtnReport = true;
        await ResultService.getReport(payload);
        // eslint-disable-next-line no-undef
        await EventBus.$emit("reportSend", true);
        await this.sleep(3000);
        await AuthService.logout(payloadLog);
        localStorage.removeItem("clientId");
        localStorage.removeItem("firstNameClient");
        localStorage.removeItem("lastNameClient");
        localStorage.removeItem("emailClient");
        localStorage.removeItem("firstNameUser");
        localStorage.removeItem("lastNameUser");
        localStorage.removeItem("formal");
        localStorage.removeItem("token");
        this.$router.push({ path: "/Start" });
      } catch (error) {
        // eslint-disable-next-line no-undef
        EventBus.$emit("errReport", true);
        this.disableBtnReport = false;
      }
    },
  },
  watch: {
    step: function () {
      if (this.step === 0) {
        this.colorStep0 = "primary";
      } else if (this.step === 1) {
        this.colorStep0 = "secondary";
        this.colorStep1 = "primary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 2) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "primary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 3) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "primary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 4) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "primary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 5) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "primary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 6) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "primary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "secondary";
      } else if (this.step === 7) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "primary";
        this.colorStep8 = "secondary";
      } else if (this.step === 8) {
        this.colorStep1 = "secondary";
        this.colorStep2 = "secondary";
        this.colorStep3 = "secondary";
        this.colorStep4 = "secondary";
        this.colorStep5 = "secondary";
        this.colorStep6 = "secondary";
        this.colorStep7 = "secondary";
        this.colorStep8 = "primary";
        this.colorStep9 = "secondary";
      } else if (this.step === 9) {
        this.colorStep8 = "secondary";
        this.colorStep9 = "primary";
      }
    },
  },
  mounted() {
    this.formal = JSON.parse(localStorage.getItem("formal"));
    this.clientId = JSON.parse(localStorage.getItem("clientId"));
    this.firstNameUser = JSON.parse(localStorage.getItem("firstNameUser"));
    this.lastNameUser = JSON.parse(localStorage.getItem("lastNameUser"));
  },
};
</script>
